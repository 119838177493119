<template>
  <div class="ContactView">
    <div class="o-container-gray">
      <div class="md-layout md-gutter">
        <md-card class="md-layout-item contact-details">
          <form novalidate class="md-layout contact-details-form">
            <md-card-header>
              <div class="md-title">{{ $t("contactView.title1") }}</div>
              <span class="o-default-body">{{ $t("contactView.subtitle1") }}</span>
            </md-card-header>

            <md-card-content>
              <div class="o-container-subsection">
                <md-list class="md-double-line">
                  <md-subheader>{{ $t("contactView.address") }}</md-subheader>

                  <md-list-item>
                    <md-icon class="md-primary">location_on</md-icon>
                    <div class="md-list-item-text">
                      <span>{{ $t("addressInreg") }}</span>
                    </div>
                  </md-list-item>

                  <md-divider></md-divider>

                  <md-subheader>{{ $t("contactView.telephone") }}</md-subheader>

                  <md-list-item>
                    <md-icon class="md-primary">phone</md-icon>
                    <div class="md-list-item-text">
                      <span @click="Helpers.copyToClipboard(messages.contactPhone); showCopyConfirmation(snackbar.text.phone);">{{ messages.contactPhone }}</span>
                      <md-tooltip md-direction="bottom">{{ messages.clickToCopy }}</md-tooltip>
                    </div>
                  </md-list-item>

                  <md-divider></md-divider>

                  <md-subheader>{{ $t("common.email") }}</md-subheader>

                  <md-list-item>
                    <md-icon class="md-primary">email</md-icon>
                    <div class="md-list-item-text">
                      <span @click="Helpers.copyToClipboard(messages.contactEmail); showCopyConfirmation(snackbar.text.email);">{{ messages.contactEmail }}</span>
                      <md-tooltip md-direction="bottom">{{ messages.clickToCopy }}</md-tooltip>
                    </div>
                  </md-list-item>
                </md-list>
              </div>
            </md-card-content>
          </form>
        </md-card>

        <md-card v-if="messageNotSent" class="md-layout-item user-details" @submit.prevent="handleSubmit">
          <form novalidate class="md-layout user-details-form">
            <md-card-header>
              <div class="md-title">{{ $t("contactView.title2") }}</div>
              <span class="o-default-body">{{ $t("contactView.subtitle2") }}</span>
            </md-card-header>

            <md-card-content>
              <div class="o-container-subsection">
                <md-field :class="getValidationClass('email')">
                  <label for="email">{{ $t("common.email") }}</label>
                  <md-input v-model.trim="email"
                            type="email"
                            name="email"
                            id="email"
                            autocomplete="email" />
                  <span class="md-error" v-if="!$v.email.required">{{ $t('contactView.enterEmailAddress') }}</span>
                  <span class="md-error" v-else-if="!$v.email.email">{{ $t('contactView.enterValidEmailAddress') }}</span>
                </md-field>

                <md-field :class="getValidationClass('name')">
                  <label for="name">{{ $t("contactView.name") }}</label>
                  <md-input v-model="name"
                            type="text"
                            name="name"
                            id="name"
                            autocomplete="name" />
                  <span class="md-error" v-if="!$v.name.required">{{ $t('contactView.enterYourName') }}</span>
                  <span class="md-error" v-else-if="!$v.name.minLength">{{ $t('contactView.invalidName') }}</span>
                </md-field>

                <div class="vue-tel-input-wrapper">
                  <vue-tel-input v-model="phone"
                                 @onInput="onPhoneInput"
                                 :preferredCountries="['ro']"
                                 :placeholder="labels.enterPhoneNumber"
                                 :disabled-fetching-country="true"
                                 mode="international"
                                 class="phone-input">
                  </vue-tel-input>
                  <div v-if="errors.showInvalidPhone" class="o-default-body u-red phone-input-error">
                    {{ $t('common.phone.error.invalid') }}</div>
                </div>

                <md-field :class="getValidationClass('text')">
                  <label>{{ $t("contactView.message") }}</label>
                  <md-textarea v-model="text"></md-textarea>
                  <span class="md-error" v-if="!$v.text.required">{{ $t('contactView.enterText') }}</span>
                  <span class="md-error" v-else-if="!$v.text.minLength">{{ $t('contactView.invalidText') }}</span>
                </md-field>

                <md-button class="md-raised md-primary handler" @click="sendMessage()">
                  {{ $t("contactView.submitMessage") }}
                </md-button>
              </div>
              <vue-recaptcha ref="invisibleRecaptcha"
                              @verify="onVerify"
                              @expired="onExpired"
                              size="invisible"
                              :sitekey="sitekey">
              </vue-recaptcha>
            </md-card-content>
          </form>
        </md-card>

        <md-card v-else class="md-layout-item card-confirmation">
          <img src="@/assets/images/checkmark.png"
              alt="Checkmark image"
              class="success-image" />
          <h3 class="o-default-h3 confirmation-text">{{ $t('contactView.confirmation') }}</h3>
        </md-card>
      </div>

      <div class="md-layout md-gutter">
        <md-card class="md-layout-item links-list">
          <md-list>
            <md-list-item v-for="(link, index) in links" :key="index" :href="link.page">{{ link.text }}</md-list-item>
          </md-list>
        </md-card>
      </div>
    </div>

    <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration" :md-active.sync="snackbar.showCopiedToClipboardSnackbar" md-persistent>
      <span>{{ $t("snackbar.copied") }} {{ snackbar.copiedContent }} {{ $t("snackbar.toClipboard") }}</span>
      <md-button class="md-primary" @click="snackbar.showCopiedToClipboardSnackbar = false" >{{ $t("common.actions.close") }}</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import {
  required,
  email,
  minLength,
} from 'vuelidate/lib/validators';
import {
    FETCH_PHONE,
    SEND_MESSAGE
  } from '@/store/actions.type';
import { validationMixin } from 'vuelidate';

export default {
  name: 'ContactView',
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      snackbar: {
        showCopiedToClipboardSnackbar: false,
        copiedContent: '',
        duration: 4000,
        position: 'center',
        text: {
          email: this.$t('snackbar.emailAddress'),
          phone: this.$t('snackbar.phoneNumber'),
        },
      },
      links: [
        {
          text: this.$t('helpView.subtitle1'),
          page: '/help/faq',
        },
        {
          text: this.$t('helpView.subtitle2'),
          page: '/help/payments',
        },
        {
          text: this.$t('helpView.subtitle3'),
          page: '/help/auctions',
        },
        {
          text: this.$t('helpView.subtitle4'),
          page: '/help/dns',
        },
      ],
      messages: {
        clickToCopy: this.$t('common.clickToCopy'),
        officeEmail: this.$t('officeEmailInreg'),
        contactEmail: this.$t('contactEmailInreg'),
        contactPhone: this.$t('contactPhoneInreg'),
      },
      email: '',
      name: '',
      phone: '',
      text: '',
      messageNotSent: true,
      sitekey: '6LehkZMUAAAAAOua6fmkshH_FUe0rkw0v45UXjIS',
      labels: {
        enterPhoneNumber: this.$t('contactView.telephone')
      },
      errors: {
        validPhone: false,
        showInvalidPhone: false,
      },
    };
  },
  validations: {
    email: {
      required,
      email
    },
    name: {
      required,
      minLength: minLength(3)
    },
    text: {
      required,
      minLength: minLength(2)
    }
  },
  computed: {
    ...mapGetters([
      'phoneNumber',
      'currentUserEmail',
      'isAuthenticated'
    ]),
  },
  mounted() {
    this.email = '';
    this.phone = '';
    if (this.isAuthenticated) {
      this.email = this.currentUserEmail;
      this.$store.dispatch(FETCH_PHONE)
      .then(() => {
        this.phone = this.phoneNumber;
        this.errors.validPhone = true;
      });
    }
  },
  methods: {
    sendMessage() {
      if (this.handleSubmit()) {
        return;
      }
      const payload = {
        email: this.email,
        name: this.name,
        phone: this.phone,
        text: this.text
      }
      this.$store.dispatch(SEND_MESSAGE, payload)
      .then(() => {
        this.messageNotSent = false;
      });
      return;
    },
    showCopyConfirmation(content) {
      this.snackbar.copiedContent = content;
      this.snackbar.showCopiedToClipboardSnackbar = true;
    },
    getValidationClass(fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    handleSubmit() {
      // stop here if form is invalid
      const validPhoneNumber = this.validatePhoneNumber();

      this.$v.$touch();
      if (!this.$v.$invalid && validPhoneNumber) {
        return false;
      }

      this.$refs.invisibleRecaptcha.execute();
      return true;
    },
    onVerify() {
      this.$refs.invisibleRecaptcha.reset();
    },
    onExpired() {
      this.$refs.invisibleRecaptcha.reset();

      console.log('Expired')
    },
    onPhoneInput({ isValid }) {
      this.errors.validPhone = isValid;
      if (isValid) {
        this.errors.showInvalidPhone= false;
      }
    },
    validatePhoneNumber() {
      if (this.errors.validPhone) {
        this.errors.showInvalidPhone = false;
        return true;
      } else {
        this.errors.showInvalidPhone = true;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactView {

  .md-toolbar {
    border: .1rem solid $black;
  }

  .md-checkbox {
    margin-right: .4rem;
  }

  .md-list-item {
    overflow: hidden;
    white-space: nowrap;
  }

  .md-list-item-text {
    cursor: pointer;
    flex: initial;
    white-space: initial;
  }

  .md-list-item-content {
    justify-content: flex-start !important;
  }

  .contact-details,
  .user-details {
    margin-right: 1.2rem;
    margin-bottom: 1.6rem;
    margin-left: 1.2rem;
    width: 100%;

    @include tablet {
      width: 50%;
    }
  }

  .contact-details-form,
  .user-details-form {
    flex-direction: column;
  }

  .links-list {
    margin-bottom: 1.8rem;
  }

  .vue-tel-input-wrapper {
    padding-bottom: 1.6rem;
  }

  .card-confirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.6rem;
    padding: 1rem 0;
  }

  .handler {
    margin-top: 2rem;
  }

  .confirmation-text {
    padding: 2rem;
  }

  .success-image {
    width: 6rem;
    height: 6rem;
  }
}
</style>

<style lang="scss">
.ContactView {

  .md-list-item-content {
    justify-content: flex-start;
  }
}
</style>
